import React, { Component } from "react"
import PropTypes from "prop-types"

export default ComposedComponent => {
  class withContext extends Component {
    static childContextTypes = {
      language: PropTypes.object,
    }

    constructor(props) {
      super()
      const { pathContext: pageContext } = props
      const { locale, languages, originalPath } = pageContext

      this.state = {
        language: {
          locale,
          languages,
          originalPath,
        },
      }
    }

    getChildContext() {
      const { language } = this.state
      return {
        language,
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }
  return withContext
}
