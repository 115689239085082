import React from 'react'
import Helmet from 'react-helmet'
import { withContext } from '../i18n'
import Link from 'gatsby-link'
import './privacy.scss'

const Privacy = () => {
  return (
    <section id="page-privacy">
      <Helmet title="Privacy Policy" />
      <div>
        <img src={"../logo@2x.png"} className="logo-elapp"/>
      </div>
      <div className="privacy-intro">
        <div className="page-content-margin">
          <div className="page-title">
            <h1>Privacy Policy</h1>
            <span>Effective as of January 26, 2021.</span>
          </div>
          <div className="privacy-intro-description">
            <p>
              This “Privacy Policy” describes the privacy practices of elapp,
              and our subsidiaries and affiliates (collectively, “Company”,
              “we”, “us”, or “our”). This Privacy Policy describes how we
              collect, use, disclose and otherwise process personal information
              in connection with our elapp services (“Services”), and
              explains the rights and choices available to individuals with
              respect to their information.
            </p>
            <p>
              Individuals located in the European Union should be sure to read
              the important information provided here.
            </p>
          </div>
        </div>
      </div>
      <div className="privacy-info">
        <div className="page-content-margin">
          <div className="privacy-info-container">
            <div className="privacy-info-content">
              <div className="privacy-info-content-title">
                <ul>
                  <li>
                    <Link to="privacy-draft/#personal">Personal Information We Collect</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#cookies">Cookies and Similar Technology</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#how-we-use">
                      How We Use Your Personal Information
                    </Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#how-we-share">
                      How We Share Your Personal Information
                    </Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#choices">Your Choices</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#security">Security</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#children">Children</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#international">International Transfer</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#other">Other Sites and Services</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#changes">Changes to this Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="privacy-draft/#additional">
                      Additional Information for European Union Users
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="privacy-info-content-description">
                <div>
                  <h3 id="personal">Personal Information We Collect</h3>
                  <span>
                    <b>Information you give us</b>
                  </span>
                  <p className="next-paragraph">
                    Personal information that you may provide through the
                    Services or otherwise communicate with us includes:
                  </p>
                  <ul>
                    <li>
                      <b>Registration and profile information.</b> When you
                      register or subscribe to any of the Services, you provide
                      us with information such as your name and avatar. 
                      You may also add other information to your account,
                      such as a profile picture.
                    </li>
                    <li>
                      <b>Feedback and correspondence information.</b> You
                      provide us information in your responses to surveys, and
                      when you participate in market research activities, report
                      a problem with the Services, receive customer support or
                      otherwise correspond with us.
                    </li>
                    <li>
                      <b>Transaction details.</b> When you make purchases
                      through the Services, you provide us with billing and
                      payment information. We use a third-party payment provider
                      to process payment card transactions. We collect the
                      transaction details only (e.g., the invoice ID, and the
                      date and amount of purchase).
                    </li>
                  </ul>
                  <p className="next-paragraph">
                    <b>Information automatically collected</b>
                  </p>
                  <ul>
                    <li>
                      <b>Usage And Log Information.</b> We may also
                      automatically log information about your use of the
                      Services, including the number of files sent
                      collectively by all users through the Services. For
                      example, we may log how many files are received per day and
                      what kind of file formats are shared.
                    </li>
                    <li>
                      <b>Device And Connection Information.</b> We may
                      automatically log information about you and your computer
                      or mobile device when you access our Services. This
                      includes information like hardware model, operating system
                      information, battery level, signal strength, app version,
                      browser information, and mobile network, connection
                      information including mobile operator or ISP, language and
                      time zone, and IP. This is to help us identify bugs and
                      deliver better help to users who contacts us with support
                      questions.
                    </li>
                    <li>
                      <b>Cookies.</b> We may use cookies to remember
                      your choices, like your language preferences, to provide a
                      safer experience, and otherwise to customize our Services
                      for you. Please refer to the Cookies and Similar
                      Technologies section for more details.
                    </li>
                  </ul>
                  <p className="next-paragraph">
                    <b>Changes to your personal information</b>
                  </p>
                  <p className="next-paragraph">
                    It is important that the personal information we hold about
                    you is accurate and current. Please let us know if your
                    personal information changes during your relationship with
                    us by updating your account.
                  </p>
                  <p className="next-paragraph">
                    <b>Information we do not collect</b>
                  </p>
                  <p className="next-paragraph">
                  Files are transferred via remote server or, if devices are in the 
                  same Wi-Fi, then through the local network. When the former occurs, 
                  files pass through our servers and are removed as soon as transfer 
                  is completed.
                  </p>
                </div>
                <div>
                  <h3 id="cookies">Cookies and Similar Technologies</h3>
                  <span>
                    <b>What are cookies?</b>
                  </span>
                  <p className="next-paragraph">
                    We may collect information using “cookies” only through our
                    Website not our Services. Cookies are small data files
                    stored on the hard drive of your computer or mobile device
                    by a website. We may use both session cookies (which expire
                    once you close your web browser) and persistent cookies
                    (which stay on your computer or mobile device until you
                    delete them) to provide you with a more personal and
                    interactive experience with our Services.
                  </p>
                  <p className="next-paragraph">
                    We use two broad categories of cookies: (1) first-party
                    cookies, served directly by us to your computer or mobile
                    device, which we use to recognize your computer or mobile
                    device when it revisits our Services; and (2) third-party
                    cookies, which are served by service providers on for our
                    Services, and can be used by such service providers to
                    recognise your computer or mobile device when it visits
                    other websites.
                  </p>
                  <p className="next-paragraph">
                    <b>Cookies we use</b>
                  </p>
                  <p className="next-paragraph">
                    Our Services use the following types of cookies for the
                    purposes set out below:
                  </p>
                  <tbody>
                    <table>
                      <tr>
                        <th>Type of cookie</th>
                        <th>Purpose</th>
                      </tr>
                      <tr>
                        <td>Essential Cookies</td>
                        <td>
                          These cookies are essential to provide you with our
                          Service offerings and to enable you to use some of our
                          features. Without these cookies, the Services that you
                          have asked for cannot be provided, and we only use
                          these cookies to provide you with those Services.
                        </td>
                      </tr>
                      <tr>
                        <td>Analytics and Performance Cookies</td>
                        <td>
                          These cookies are used to collect information about
                          our Services’ traffic and how users use our Services.
                          The information gathered may include the number of
                          visitors who use our Services, the websites that
                          referred them to our Services, what time of day they
                          used our Services, whether they have used our Services
                          before, and other similar information. We use this
                          information to help operate our Services more
                          efficiently, to gather broad demographic information,
                          monitor the level of activity, and improve the
                          Services.
                        </td>
                      </tr>
                      <tr>
                        <td>Social Media Cookies</td>
                        <td>
                          These cookies are used when you share information
                          using a social media sharing button or “like” button
                          through our Services or you link your account or
                          engage with our content on or through a social
                          networking website such as Facebook or Twitter. The
                          social network will record that you have done this.
                        </td>
                      </tr>
                      <tr>
                        <td>Targeted and advertising cookies</td>
                        <td>
                          These cookies track your browsing habits to enable us
                          to show advertising which is more likely to be of
                          interest to you. These cookies use information about
                          your browsing history to group you with other users
                          who have similar interests. Based on that information,
                          third-party advertisers can place cookies to enable
                          them to show advertisements which we think will be
                          relevant to your interests while you are on
                          third-party websites.
                          <p className="next-paragraph">
                            You can disable certain cookies which remember your
                            browsing habits and target advertising at you by
                            visiting this site. If you choose to remove targeted
                            or advertising cookies, you will still see
                            advertisements but they may not be relevant to you.
                            Even if you do choose to remove cookies by the
                            companies listed at the above link, not all
                            companies that serve online behavioural advertising
                            are included in this list, and so you may still
                            receive some cookies and tailored adverts from
                            companies that are not listed.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </tbody>
                  <p>
                    <b>Disabling cookies</b>
                  </p>
                  <p className="next-paragraph">
                    You can typically remove or reject cookies via your browser
                    settings. In order to do this, follow the instructions
                    provided by your browser (usually located within the
                    “settings,” “help” “tools” or “edit” facility). Many
                    browsers are set to accept cookies until you change your
                    settings.
                  </p>
                  <p className="next-paragraph">
                    For further information about cookies, including how to see
                    what cookies have been set on your computer or mobile device
                    and how to manage and delete them, visit&nbsp;
                    <a href="http://www.allaboutcookies.org" target="_blank">
                      www.allaboutcookies.org.
                    </a>
                  </p>
                  <p className="next-paragraph">
                    If you do not accept our cookies, you may experience some
                    inconvenience in your use of our Services. For example, we
                    may not be able to recognize your computer or mobile device
                    and you may need to log in every time you use our Services.
                  </p>
                  <p className="next-paragraph">
                    <b>Do Not Track signals</b>
                  </p>
                  <p className="next-paragraph">
                    Some Internet browsers may be configured to send &quot;Do
                    Not Track&quot; signals to the online services that you
                    visit. We currently do not respond to “Do Not Track”
                    signals. To find out more about &quot;Do Not Track,&quot;
                    please visit&nbsp;
                    <a href="https://allaboutdnt.com" target="_blank">
                      http://www.allaboutdnt.com.
                    </a>
                  </p>
                </div>
                <div>
                  <h3 id="how-we-use">How We Use Your Personal Information</h3>
                  <p>
                    <b>To provide our Services</b>
                  </p>
                  <p className="next-paragraph">
                    If you have a elapp account or use our Services, we use
                    your personal information to:
                  </p>
                  <ul>
                    <li>
                      Operate, maintain, administer and improve the Services;
                    </li>
                    <li>
                      Manage and communicate with you regarding your elapp
                      account, including by sending you service announcements,
                      technical notices, updates, security alerts and support
                      and administrative messages;
                    </li>
                    <li>
                      Provide support and maintenance for the Services; and
                    </li>
                    <li>
                      Respond to your service-related requests, questions and
                      feedback.
                    </li>
                  </ul>
                  <p>
                    <b>To communicate with you</b>
                  </p>
                  <p className="next-paragraph">
                    If you request information from us, register for the
                    Services, or participate in our surveys, promotions or
                    events, we may send you elapp-related marketing
                    communications as permitted by law. You will have the
                    ability to opt out of such communications.
                  </p>
                  <p className="next-paragraph">
                    <b>To comply with law</b>
                  </p>
                  <p className="next-paragraph">
                    We use your personal information as we believe necessary or
                    appropriate to comply with applicable laws, lawful requests
                    and legal process, such as to respond to subpoenas or
                    requests from government authorities.
                  </p>
                  <p className="next-paragraph">
                    <b>With your consent</b>
                  </p>
                  <p className="next-paragraph">
                    We will request your consent to use your personal data where
                    required by law, such as where we use certain cookies or
                    similar technologies or would like to send you certain
                    marketing messages. If we request your consent to use your
                    personal data, you have the right to withdraw your consent
                    any time in the manner indicated when we requested the
                    consent or by contacting us.
                  </p>
                  <p className="next-paragraph">
                    <b>To create anonymous data for analytics</b>
                  </p>
                  <p className="next-paragraph">
                    We may create anonymous data from the information you
                    provide to us through the Services. We exclude any
                    information that may make the data personally identifiable
                    to you. We reserve the right to use that anonymous data for
                    our lawful business purposes.
                  </p>
                  <p className="next-paragraph">
                    <b>For compliance, fraud prevention and safety</b>
                  </p>
                  <p className="next-paragraph">
                    We use your personal information as we believe necessary or
                    appropriate to (a) enforce the terms and conditions that
                    govern the Service; (b) protect our rights, privacy, safety
                    or property, and/or that of you or others; and (c) protect,
                    investigate and deter against fraudulent, harmful,
                    unauthorized, unethical or illegal activity.
                  </p>
                </div>
                <div>
                  <h3 id="how-we-share">
                    How We Share Your Personal Information
                  </h3>
                  <p>
                    <b>Affiliates</b>
                  </p>
                  <p className="next-paragraph">
                    We may disclose your personal information to our
                    subsidiaries and corporate affiliates for purposes
                    consistent with this Privacy Policy.
                  </p>
                  <p className="next-paragraph">
                    <b>Service providers</b>
                  </p>
                  <p className="next-paragraph">
                    We may employ third-party companies and individuals to
                    administer and provide the Services on our behalf (such as
                    training, customer support, hosting, email delivery and
                    database management services). These third parties may use
                    your information only as directed by Company and in a manner
                    consistent with this Privacy Policy, and are prohibited from
                    using or disclosing your information for any other purpose.
                  </p>
                  <p className="next-paragraph">
                    <b>Professional advisors</b>
                  </p>
                  <p className="next-paragraph">
                    We may disclose your personal information to professional
                    advisors, such as lawyers, bankers, auditors and insurers,
                    where necessary in the course of the professional services
                    that they render to us.
                  </p>
                  <p className="next-paragraph">
                    <b>
                      Compliance with laws and law enforcement; protection and
                      safety
                    </b>
                  </p>
                  <p className="next-paragraph">
                    Company may disclose information about you to government or
                    law enforcement officials or private parties as required by
                    law, and disclose and use such information as we believe
                    necessary or appropriate to (a) comply with applicable laws
                    and lawful requests and legal processes, such as to respond
                    to subpoenas or requests from government authorities; (b)
                    enforce the terms and conditions that govern the Service;
                    (d) protect our rights, privacy, safety or property, and/or
                    that of you or others; and (e) protect, investigate and
                    deter against fraudulent, harmful, unauthorized, unethical
                    or illegal activity.
                  </p>
                  <p className="next-paragraph">
                    <b>Business transfers</b>
                  </p>
                  <p className="next-paragraph">
                    Company may sell, transfer or otherwise share some or all of
                    its business or assets, including your personal information,
                    in connection with a business deal (or potential business
                    deal) such as a merger, consolidation, acquisition,
                    reorganization or sale of assets or in the event of
                    bankruptcy, in which case we will make reasonable efforts to
                    require the recipient to honor this Privacy Policy.
                  </p>
                </div>
                <div>
                  <h3 id="choices">Your Choices</h3>
                  <p>
                    <b>Access, update, correct or delete your information</b>
                  </p>
                  <p className="next-paragraph">
                    You may review, update, correct or delete your personal
                    information by logging into your account. If you choose not
                    to provide your personal information to us, or limit how may
                    process it, we may not be able to provide you with the
                    Services and may need to close your account, if your choices
                    are inconsistent with our legal obligations to process data
                    or prevent us from providing Services to you. We will tell
                    you what information you must provide to receive the
                    Services by designating it as required in the Services or
                    through other appropriate means.
                  </p>
                  <p className="next-paragraph">
                    You can stop all collection of information by elapp
                    application by uninstalling the application. You may use the
                    standard uninstall processes as may be available as part of
                    your computer device, mobile device or via the mobile
                    application marketplace or network. You may at any time opt
                    out from further allowing us to have access to your location
                    data by disabling the location sharing on your mobile
                    device.
                  </p>
                </div>
                <div>
                  <h3 id="security">Security</h3>
                  <p>
                    The security of your personal information is important to
                    us. We take a number of organizational, technical and
                    physical measures designed to protect the personal
                    information we collect, both during transmission and once we
                    receive it. However, no security safeguards are 100% secure
                    and we cannot guarantee the security of your information.
                  </p>
                  <p className="next-paragraph">
                    You should take steps to protect against unauthorized access
                    to your password and computer by, among other things,
                    signing off after using a shared computer, choosing a robust
                    password that nobody else knows or can easily guess, and
                    keeping your log-in and password private. We are not
                    responsible for any lost, stolen or compromised passwords or
                    for any activity on your account via unauthorized password
                    activity.
                  </p>
                </div>
                <div>
                  <h3 id="children">Children</h3>
                  <p>
                    Our Services are not directed to children under 16. If a
                    parent or guardian becomes aware that his or her child has
                    provided us with information without his or her consent, he
                    or she should contact us. We will delete such information
                    from our files as soon as reasonably practicable.
                  </p>
                </div>
                <div>
                  <h3 id="international">International Transfer</h3>
                  <p>
                    elapp is headquartered in the United States and has
                    affiliates and service providers in other countries. Your
                    personal information may be transferred to the United States
                    or other locations outside of your state, province, country
                    or other governmental jurisdiction where privacy laws may
                    not be as protective as those in your jurisdiction.
                  </p>
                </div>
                <div>
                  <h3 id="other">Other Sites and Services</h3>
                  <p>
                    The Services may contain links to other websites and
                    services. These links are not an endorsement, authorization
                    or representation that we are affiliated with that third
                    party. We do not exercise control over third-party websites
                    or services, and are not responsible for their actions.
                    Other websites and services follow different rules regarding
                    the use or disclosure of the personal information you submit
                    to them. We encourage you to read the privacy policies of
                    the other websites you visit and services you use.
                  </p>
                </div>
                <div>
                  <h3 id="changes">Changes to this Privacy Policy</h3>
                  <p>
                    We reserve the right to modify this Privacy Policy at any
                    time. We encourage you to periodically review this page for
                    the latest information on our privacy practices. If we make
                    material changes to this Privacy Policy you will be notified
                    via email (if you have an account where we have your contact
                    information) or another manner through the Services that we
                    believe reasonably likely to reach you (which may include
                    posting a new privacy policy related to our Services).
                  </p>
                  <p className="next-paragraph">
                    Any modifications to this Privacy Policy will be effective
                    upon our posting of the new terms and/or upon implementation
                    of the new changes on the Service (or as otherwise indicated
                    at the time of posting). In all cases, your continued use of
                    the Services after the posting of any modified Privacy
                    Policy indicates your acceptance of the terms of the
                    modified Privacy Policy.
                  </p>
                </div>
                <div>
                  <h3 id="contact">Contact Us</h3>
                  <p>
                    If you have any questions or concerns at all about our
                    Privacy Policy, please feel free to email us at
                    elapp@criptext.atlassian.net
                  </p>
                </div>
                <div>
                  <h3 id="additional">
                    Additional Information for European Union Users
                  </h3>
                  <p>
                    <b>Personal information</b>
                  </p>
                  <p className="next-paragraph">
                    References to “personal information” in this Privacy Policy
                    are equivalent to “personal data” governed by European data
                    protection legislation.
                  </p>
                  <p className="next-paragraph">
                    <b>Controller</b>
                  </p>
                  <p className="next-paragraph">
                    elapp is the controller of your personal
                    information for purposes of European data protection
                    legislation. See the Contact Us section above for contact
                    details.
                  </p>
                  <p className="next-paragraph">
                    <b>Legal basis for processing</b>
                  </p>
                  <p className="next-paragraph">
                    We only use your personal information as permitted by law.
                    We are required to inform you of the legal basis of our
                    processing of your personal information, which are described
                    in the table below. If you have questions about the legal
                    basis of how we process your personal information, contact
                    us at elapp@criptext.atlassian.net.
                  </p>
                  <tbody>
                    <table>
                      <tr>
                        <th>
                          Processing purpose <br />
                        </th>
                        <th>Legal basis</th>
                      </tr>
                      <tr>
                        <td>
                        To provide the Services
                        </td>
                        <td>
                          Processing is necessary to perform the contract
                          governing our provision of the Services or to take
                          steps that you request prior to signing up for the
                          Services.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li>
                            To communicate with you
                            </li>
                            <li>
                            To create anonymous data for analytics
                            </li>
                            <li>
                            For compliance, fraud prevention and safety
                            </li>
                          </ul>
                        </td>
                        <td>
                          These processing activities constitute our legitimate
                          interests. We make sure we consider and balance any
                          potential impact on you (both positive and negative)
                          and your rights before we process your personal
                          information for our legitimate interests. We do not
                          use your personal information for activities where our
                          interests are overridden by the impact on you (unless
                          we have your consent or are otherwise required or
                          permitted to by law).
                        </td>
                      </tr>
                      <tr>
                        <td>
                        To comply with law
                        </td>
                        <td>
                          Processing is necessary to comply with our legal
                          obligations.
                        </td>
                      </tr>
                      <tr>
                        <td>
                        With your consent
                        </td>
                        <td>
                          Processing is based on your consent. Where we rely on
                          your consent you have the right to withdraw it anytime
                          in the manner indicated in the Services or by
                          contacting us at elapp@criptext.atlassian.net.
                        </td>
                      </tr>
                    </table>
                  </tbody>
                  <p>
                    <b>Sensitive personal data</b>
                  </p>
                  <p className="next-paragraph">
                    If you send or disclose any sensitive personal
                    data through the Services, we process such sensitive
                    personal data in accordance with this Privacy Policy,
                    pursuant to your consent. You may withdraw your consent at
                    any time by deleting your account or contacting us at
                    elapp@criptext.atlassian.net. If you withdraw your consent, we will
                    terminate your account.
                  </p>
                  <p className="next-paragraph">
                    <b>Retention</b>
                  </p>
                  <p className="next-paragraph">
                    We will only retain your personal information for as long as
                    necessary to fulfil the purposes we collected it for,
                    including for the purposes of satisfying any legal,
                    accounting or reporting requirements.
                  </p>
                  <p className="next-paragraph">
                    To determine the appropriate retention period for personal
                    information, we consider the amount, nature, and sensitivity
                    of the personal information, the potential risk of harm from
                    unauthorized use or disclosure of your personal information,
                    the purposes for which we process your personal information
                    and whether we can achieve those purposes through other
                    means, and the applicable legal requirements.
                  </p>
                  <p className="next-paragraph">
                    In some circumstances we may anonymize your personal
                    information (so that it can no longer be associated with
                    you) in which case we may use this information indefinitely
                    without further notice to you.
                  </p>
                </div>
                <div>
                  <h3>Your rights</h3>
                  <p>
                    European data protection laws give you certain rights
                    regarding your personal information. You may ask us to take
                    the following actions in relation to your personal
                    information that we hold:
                  </p>
                  <ul>
                    <li>
                      <b>Opt-out.</b> Stop sending you direct marketing
                      communications. You may continue to receive service-
                      related and other non-marketing emails.
                    </li>
                    <li>
                      <b>Access.</b> Provide you with information about our
                      processing of your personal information and give you
                      access to your personal information.
                    </li>
                    <li>
                      <b>Correct.</b> Update or correct inaccuracies in your
                      personal information.
                    </li>
                    <li>
                      <b>Delete.</b> Delete your personal information.
                    </li>
                    <li>
                      <b>Transfer.</b> Transfer a machine-readable copy of your
                      personal information to you or a third party of your
                      choice.
                    </li>
                    <li>
                      <b>Restrict.</b> Restrict the processing of your personal
                      information.
                    </li>
                    <li>
                      <b>Object.</b> Object to our reliance on our legitimate
                      interests as the basis of our processing of your personal
                      information.
                    </li>
                  </ul>
                  <p>
                    You can submit these requests by email to
                    elapp@criptext.atlassian.net or our postal address provided above.
                    We may request specific information from you to help us
                    confirm your identity and process your request. Applicable
                    law may require or permit us to decline your request. If we
                    decline your request, we will tell you why, subject to legal
                    restrictions. If you would like to submit a complaint about
                    our use of your personal information or response to your
                    requests regarding your personal information, you may
                    contact us as described above or submit a complaint to the
                    data protection regulator in your jurisdiction. You can find
                    your data protection regulator here.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withContext(Privacy)